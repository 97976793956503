import React from "react"
import "../styles/ImgMenu.css"
import Div100vh from "react-div-100vh"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import home from "../../assets/images/icons/icons_menu/1.png"
// import sunset from "../../assets/images/icons/icons_menu/5.png"
// import quiero_miCorona from "../../assets/images/icons/icons_menu/2.png"
// import casa_corona from "../../assets/images/icons/icons_menu/4.png"
// import protect_paradise from "../../assets/images/icons/icons_menu/3.png"
import facebook from "../../assets/images/social/Facebook.svg"
import facebookWhite from "../../assets/images/social/FacebookWhite.svg"
import instagram from "../../assets/images/social/Instagram.svg"
import instagramWhite from "../../assets/images/social/InstagramWhite.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const ImgMenu = ({ isOpen, light, store = null, toggleMenu = null }) => {
  const sendMenuAnalytics = () => {
    window.dataLayer.push({
      'event' : 'GAEvent',
      'event_category': 'menu',
      'event_action':'Click',
      'event_label': 'Herencia',
      'interaction': 'True',
      'component_name': '',
      'element_text': '',
    });
  }

  return <Div100vh className={isOpen ? "slideIn" : "slideOut"}>
    <div className={light ? "menuBackgroundLight" : "menuBackground"}>
      {store && (
          <div className="menuBackground_store">
            <FontAwesomeIcon
                className="menuBackground_store-icon"
                icon={faTimes}
                onClick={toggleMenu}
            />
          </div>
      )}
      <div className="row menuContent">
        <div className="col d-flex align-items-center justify-content-center">
          <ul className="menuUl">
            <AniLink to="/" cover direction="left" duration={0.5} bg="#ffc311">
              <li className="menuLink" onClick={sendMenuAnalytics}>
                <div alt="Home" className="menuImgLink homeIcon" />
                Home
              </li>
            </AniLink>
            {/* <AniLink
                            to="/quieromicorona"
                            cover
                            direction="left"
                            duration={0.5}
                            bg="#ffc311"
                        >
                            <li className="menuLink">
                                <div alt="quieromiCorona" className="menuImgLink quieroIcon" />
                                Quiero mi corona
                            </li>
                        </AniLink>*/}
            {/*<AniLink
                            to="/protectparadise"
                            cover
                            direction="left"
                            duration={0.5}
                            bg="#ffc311"
                        >
                            <li className="menuLink">
                                <div
                                    alt="protectparadise"
                                    className="menuImgLink protectIcon"
                                />
                                Protect Paradise
                            </li>
                        </AniLink>*/}
            <AniLink
                to="/casacorona"
                cover
                direction="left"
                duration={0.5}
                bg="#ffc311"
            >
              <li className="menuLink" onClick={sendMenuAnalytics}>
                <div alt="casacorona" className="menuImgLink casaIcon" />
                Casa corona
              </li>
            </AniLink>
            <AniLink
                to="/corona-sunset"
                cover
                direction="left"
                duration={0.5}
                bg="#ffc311"
            >
              <li className="menuLink" onClick={sendMenuAnalytics}>
                <div alt="sunset" className="menuImgLink sunsetIcon" />
                Corona sunset
              </li>
            </AniLink>
            {/* <AniLink
              to="/corona-store"
              cover
              direction="left"
              duration={0.5}
              bg="#ffc311"
            >
              <li className="menuLink">
                <div alt="sunset" className="menuImgLink quieroIcon" />
                Corona Store
              </li>
            </AniLink> */}
            {/* <AniLink to="/anywhereoffice" cover direction="left" duration={.5} bg="#ffc311">
                            <li className="menuLink"><div  alt="sunset" className="menuImgLink sunsetIcon" />Anywhereoffice</li>
                        </AniLink> */}
            <div className={"socialMenuContainer"}>
              <a href="https://www.facebook.com/cervezacorona" target="_blank">
                <img
                    className={"imgSocialMenu"}
                    src={light ? facebookWhite : facebook}
                />
              </a>
              <a
                  href="https://www.instagram.com/corona_argentina"
                  target="_blank"
              >
                <img
                    className={"imgSocialMenuInsta"}
                    src={light ? instagramWhite : instagram}
                />
              </a>
            </div>
            <div className="column justify-content-center align-content-center mt-md-3">
              <div className="col">
                <div className="row  mt-md-5 mt-lg-4 justify-content-center">
                  <div className="col-12 termItem">
                    <a
                        className="menuLink termLink"
                        href={"/TyCCervezaCorona.pdf"}
                        target="_blank"
                        download
                    >
                      Términos de uso |
                    </a>
                    <br />
                    <a
                        className="menuLink termLink"
                        href={"/ppdp.pdf"}
                        target="_blank"
                        download
                    >
                      Políticas de privacidad
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </Div100vh>
}

export default ImgMenu

{
  /* <BackgroundImage
            style={{backgroundPosition: 'top', height: '100%', width: '100%'}}
            className={'background-im'}
            fluid={data.placeholderImage.childImageSharp.fluid}
            backgroundColor={`#21273C`}
            >
          </BackgroundImage> */
}
